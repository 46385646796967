import React from "react";
import "../styles/App.css";
import mail from "../assets/mail.png";

function Footer() {
  return (
    <>
      <footer className="container footer">
        <div className="footerBox">
          <h1 className="getInTouch">Get in touch</h1>
          <p className="footerText">
            Think we should work together? Send us a message and we’ll get in
            touch with you. Let’s build together!
          </p>
          <a href="mailto:contact@milkjug.io" className="contactUs">
            <img src={mail} className="mail" />
            Contact us
          </a>
        </div>
        <div className="footerNav">
          <p>Copyright © 2022 Milkjug LLC</p>
          <div className="footerNavItem">
            <p>Cookies</p>
            <p>Security</p>
            <p>Terms of Service</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
