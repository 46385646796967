import React from "react";

import Header from "./Header";

import "../styles/App.css";
import Banner from "./Banner";
import About from "./About";
import Feature from "./Feature";
import Footer from "./Footer";

function App() {
  return (
    <>
      <Header />
      <Banner />
      <About />
      <Feature />
      <Footer />
    </>
  );
}

export default App;
