import React from "react";
import "../styles/App.css";

import f1 from "../assets/f1.png";
import f2 from "../assets/f2.png";
import f3 from "../assets/f3.png";

import fArrow from "../assets/fArrow.png";

function Feature() {
  return (
    <>
      <section>
        <div className="container feature">
          <div className="featureBoxOne">
            <img src={f1} className="fImages" />

            <div className="featureOneText">
              <h1>Lost and Found just got a lot easier.</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                luctus, dui nec auctor vulputate, nibh purus posuere lectus,
                eget feugiat dolor dolor a elit. Integer tristique, tellus eu
                efficitur consequat, nunc justo aliquam lacus, cursus porta
                lectus ex et mauris.
              </p>
              <a
                href="https://tags.milkjug.io"
                target={"_blank"}
                className="learnmoreBtn"
              >
                Tags by Milkjug
                <img src={fArrow} className="fArrow" />
              </a>
              <p>
                Frontend Development • Digitally Linked Ownership • Open Beta •
                Waitlist
              </p>
            </div>
          </div>

          <div className="featureBoxTwo">
            <div className="featureOneText">
              <h1>We hear music, why not touch it too.</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                luctus, dui nec auctor vulputate, nibh purus posuere lectus,
                eget feugiat dolor dolor a elit. Integer tristique, tellus eu
                efficitur consequat, nunc justo aliquam lacus, cursus porta
                lectus ex et mauris.
              </p>
              <span href="#" className="learnmoreBtn">
                Coming soon
                {/* <img src={fArrow} className="fArrow" /> */}
              </span>
              <p>Raspberry Pi • Open Beta • Do-It-Yourself</p>
            </div>

            <img src={f2} className="fImages" />
          </div>

          <div className="featureBoxThree">
            <img src={f3} className="fImages" />

            <div className="featureOneText">
              <h1>There’s more where that come from…</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                luctus, dui nec auctor vulputate, nibh purus posuere lectus,
                eget feugiat dolor dolor a elit. Integer tristique, tellus eu
                efficitur consequat, nunc justo aliquam lacus, cursus porta
                lectus ex et mauris.
              </p>
              <a
                href="https://account.milkjug.io/signup"
                target={"_blank"}
                className="bannerCreateBtn"
              >
                <div className="bannerCreateBtnText">
                  <p className="bannerCreateName">Create Account</p>
                  <img src={fArrow} alt="arrowRight" className="arrowRight" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Feature;
