import React from "react";
import logo from "../assets/logo.svg";
import arrowRight from "../assets/arrow-right.png";
import "../styles/Header.css";

import { useDeviceBreakpoint } from "../hooks/useDeviceBreakpoint";

function Header() {
  const breakpointProps = useDeviceBreakpoint("lg");

  console.log(breakpointProps);

  return (
    <>
      <header>
        <div className="container" id="mainHeader">
          <div className="logoContainer">
            <img src={logo} alt="milkjug" className="logoImage" />
            <span className="logoText">Milkjug</span>
          </div>
          <div className="loginAndCreate">
            <a
              href={
                breakpointProps.isExtraSmall
                  ? "https://account.milkjug.io/signin"
                  : "https://account.milkjug.io/signup"
              }
              target={"_blank"}
              className="createBtn"
            >
              <div className="createBtnText">
                <p className="createName">
                  {breakpointProps.isExtraSmall ? "Login" : "Create Account"}
                </p>
                <img src={arrowRight} alt="arrowRight" className="arrowRight" />
              </div>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
