import React from "react";
import "../styles/App.css";

import facebookBrands from "../assets/facebook-brands.png";
import googleBrands from "../assets/google-brands.png";
import microsoftBrands from "../assets/microsoft-brands.png";
import airbnbBrands from "../assets/airbnb-brands.png";
import dropboxBrands from "../assets/dropbox-brands.png";
import uberBrands from "../assets/uber-brands.png";
import shopifyBrands from "../assets/shopify-brands.png";
import pinterestBrands from "../assets/pinterest-brands.png";
import p1 from "../assets/p1.png";
import p2 from "../assets/p2.png";

function Grid({ size, color, col, row, spacing, style }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${col}, ${size}px)`,
        gridTemplateRows: `repeat(${row}, ${size}px)`,
        gridGap: spacing,
        ...(style || {}),
      }}
    >
      {[...Array(col * row)].map((_, i) => (
        <div
          key={i}
          style={{
            height: size,
            width: size,
            backgroundColor: color,
            borderRadius: 10000,
          }}
        />
      ))}
    </div>
  );
}

function About() {
  const gridPropsLeft = {
    size: 8,
    color: "#2E66F922",
    col: 10,
    row: 10,
    spacing: 16,
    style: {
      position: "absolute",
      left: -((9 * 16 + 8 * 10) / 2),
      top: -((9 * 16 + 8 * 10) / 2),
      zIndex: -1,
    },
  };

  const gridPropsRight = {
    ...gridPropsLeft,
    style: {
      ...gridPropsLeft.style,
      left: "unset",
      top: "unset",
      right: gridPropsLeft.style.left,
      bottom: gridPropsLeft.style.top,
    },
  };
  return (
    <>
      <section>
        <div className="container" id="about">
          <p className="trustText">We use technologies trusted by the best</p>
          <div className="techBox">
            <img src={facebookBrands} className="techBrand" />
            <img src={googleBrands} className="techBrand" />
            <img src={microsoftBrands} className="techBrand" />
            <img src={airbnbBrands} className="techBrand" />
            <img src={dropboxBrands} className="techBrand" />
            <img src={uberBrands} className="techBrand" />
            <img src={shopifyBrands} className="techBrand" />
            <img src={pinterestBrands} className="techBrand" />
          </div>
          <div className="whatis">
            <h1 className="whatisHeading">What exactly is Milkjug?</h1>
            <p className="whatisText">
              As people who have experienced the struggle of consistently
              cataloging and sharing our projects, Milkjug is our space to build
              projects in public.
              <br />
              <br />
              Follow us through the process of designing one off DIY projects,
              brainstorming the logistics of a new business, or building an open
              beta for everyone to try out.
            </p>
          </div>

          <div className="twoGrids">
            <div className="peopleBox">
              <div className="people">
                <Grid {...gridPropsLeft} />
                <img src={p1} className="p1" />
                <h1>Jon Moore</h1>
                <p>
                  Sales rep by day & innovator by night - Jon handles human
                  relations, user testing, idea generation, and much more!
                </p>
              </div>

              <div className="people">
                <Grid {...gridPropsRight} />
                <img src={p2} className="p2" />
                <h1>Zach Moore</h1>
                <p>
                  Coder by day & coder by night - Zach handles system design,
                  manufacturing, coding, and much more!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
