import React from "react";

import bannerBackground from "../assets/bannerBackground.png";
import bannerRight from "../assets/bannerRight.png";
import arrowRight from "../assets/arrow-right.png";
import "../styles/App.css";

function Banner() {
  return (
    <>
      <section id="banner">
        <div className="liner"></div>
        <div className="container bannerContent">
          <div className="bannerLeftSide">
            <div className="headingBox">
              <h1 className="bannerHeading">We make ideas a reality ⚡</h1>
            </div>
            <div>
              <p className="bannerText">
                Milkjug is a small team of individuals with a passion for
                design, creativity, and prototyping. We simply love making
                awesome stuff.
              </p>
            </div>

            <a
              href="https://account.milkjug.io/signup"
              target={"_blank"}
              className="bannerCreateBtn"
            >
              <div className="bannerCreateBtnText">
                <p className="bannerCreateName">Create Account</p>
                <img src={arrowRight} alt="arrowRight" className="arrowRight" />
              </div>
            </a>
          </div>
          <div className="bannerRightSide">
            <img src={bannerRight} className="bannerRight" />
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
